import React from "react";
import './Card.css';

type CardProps = {
    className? : string,
    children : React.ReactNode
    showingError? : boolean
};

const Card = ({className, children, showingError = false} : CardProps) => {

    return (
        <div className={`${className || ''} card ${showingError ? 'error' : ''}`}>
            {children}
        </div>
    );
};

export default Card;