export type PlatformId = string;

export class SiteLinks {
    about_us: string;
    privacy: string;
    legal: string;

    constructor(data: any) {
        this.about_us = data.about_us;
        this.privacy = data.privacy;
        this.legal = data.legal;
    }
}

export class SiteSettings {

    platform_id: PlatformId;
    product_name: string;
    page_title: string;
    logo: string;
    links: SiteLinks;

    constructor(data: any) {
        this.platform_id = data.platform;
        this.product_name = data.product_name;
        this.page_title = data.page_title;
        this.logo = data.logo;
        this.links = new SiteLinks(data.links);
    }

    getPlatformId() : PlatformId {
        return this.platform_id;
    }

    static getCurrentSettings() : Promise<SiteSettings> {
        return fetch(`settings.json`).then(response => {
            if(response.status >= 400 && response.status <= 599) {
                throw new Error("fetching current site settings failed");
            }
            return response.json();
        }).then(data => {
            return new SiteSettings(data);
        });
    }
}

export default SiteSettings;
