import React from "react";
import './Header.css';

const Header = () => {
    return (
        <header className={"header"}>
        </header>
    );
};

export default Header;