import React, { useState } from 'react';
import './PinningProjectInfoView.css';

export const PinningProjectInfoView = ({} : {}) => {

    const [showDetails, setShowDetails] = useState<boolean>(false);

    return (
        <div className={"pinning-project-info"}>
            For easy access to datasets in the future it can be helpful to <em>`Star`</em> the owning project.
            Click the "Star" icon within the BigQuery Studio dataset list to mark it as starred (example below).

            <img src={"bigquery-studio-star.png"} width={"250px"} />

            Alternatively, you can manually add projects to your set of starred projects by name.&nbsp;
            <span className={"show-details"} onClick={() => setShowDetails(!showDetails)}>Show Details</span>

            {showDetails ?
                <ul>
                    <li>
                        Click the "+Add" button in the top-left hand corner area of the BigQuery Studio window.
                        <img src={"explorer-click-add.png"} width={350} />
                    </li>
                    <li>
                        Select the option to "Star a project by name" from the list of options shown.
                        <img src={"star-by-name.png"} width={450} />
                    </li>
                    <li>
                        Enter the project name for the dataset you want to add to your set of starred projects. The project names
                        for the Digital Science datasets associated with this invitation are listed above for each product.
                        <img src={"star-project-name.png"} width={250} />
                    </li>
                </ul>
                :
                <></>
            }

        </div>
    );
};

export default PinningProjectInfoView;
