import React from 'react';
import './ProductDetailsView.css';

import {Product} from "../model/UserInvite";
import SiteSettings from "../model/SiteSettings";

export const ProductDetailsView = ({siteSettings, product, userGoogleAccount, managedProjectId} : {siteSettings : SiteSettings, product: Product, userGoogleAccount: string, managedProjectId?: (string | null)}) => {

    if(!product.location) {
        return <></>;
    }

    const location = product.location;
    const projectWorkspace = `ws=!1m4!1m3!3m2!1s${encodeURIComponent(location.project)}!2s${encodeURIComponent(location.dataset)}`;

    let projectLinkQuery = `authuser=${encodeURIComponent(userGoogleAccount)}`;
    let displayLinkQuery = ``;

    if(managedProjectId) {
        projectLinkQuery = `${projectLinkQuery}&project=${encodeURIComponent(managedProjectId)}`;
        displayLinkQuery = `project=${encodeURIComponent(managedProjectId)}`
    }

    projectLinkQuery = `${projectLinkQuery}&${projectWorkspace}`;
    if(displayLinkQuery.length > 0) {
        displayLinkQuery =  `${displayLinkQuery}&${projectWorkspace}`;
    } else {
        displayLinkQuery = `${projectWorkspace}`;
    }

    const bigQueryProjectLink = `https://console.cloud.google.com/bigquery?${projectLinkQuery}`;
    const bigQueryDisplayLink = `https://console.cloud.google.com/bigquery?${displayLinkQuery}`;

    return (
        <div className={"product-info"}>
            <img src={`/${product.productIconSource()}`} alt={product.name} /> <span className={"product-name"}>{product.name}</span> – to access the BigQuery dataset use the link below as a starting point:
            <br />
            <a href={bigQueryProjectLink} target="_blank" rel="noopener noreferrer">
                <LinkIcon className={'link-icon'} />
                {bigQueryDisplayLink}
            </a>
            <div className={"project-id"}>
                Dataset Project: <em>{`${location.project}`}</em>
            </div>
        </div>
    );
};

export default ProductDetailsView;

export const LinkIcon = ({className} : {className? : string}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" className={className || ''}>
            <path d="M 3 3 L 3 21 L 21 21 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 3 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
        </svg>
    )
}