import React from 'react';
import './LoadingIndicator.css';


export enum LoaderSize {
    Normal,
    Small,
    Large
}

export enum LoaderStyle {
    SimpleCircle,
    BouncingCircle
}

const LoadingIndicator = ({style, size= LoaderSize.Normal} : {style : LoaderStyle, size? : LoaderSize}) => {

    let sizingClass = '';

    if(size === LoaderSize.Small) {
        sizingClass = 'small';
    } else if(size === LoaderSize.Large) {
        sizingClass = 'large';
    }

    if(style === LoaderStyle.SimpleCircle) {
        return (
            <div className={`loader simple-circle ${sizingClass}`}>
                <div />
            </div>
        );
    }

    if(style === LoaderStyle.BouncingCircle) {
        return (
            <div className={`loader bouncing-circle ${sizingClass}`}>
                <div className="double-bounce1" />
                <div className="double-bounce2" />
            </div>
        );
    }

    return null;
};

export default LoadingIndicator;