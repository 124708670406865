import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <footer className={"footer"}>
            <span className={'text-small'}>© 2024 Digital Science & Research Solutions Inc. All Rights Reserved</span>
        </footer>
    );
};

export default Footer;