import React from "react";
import './CardHeader.css';

type CardHeaderProps = {
    className? : string,
    children : React.ReactNode
};

const CardHeader = ({className, children} : CardHeaderProps) => {
    return (
        <div className={`${className || ''} header`}>
            {children}
        </div>
    );
};

export default CardHeader;