import React, {useState, useEffect} from 'react';
import './GoogleAccountLinkingView.css';

import LoadingIndicator, { LoaderStyle } from './LoadingIndicator';
import GoogleAssociateButton, { GoogleAssociateInProgressButton } from "./GoogleAssociateButton";

import UserInvite, { FailedGoogleAccountLinkingError } from "../model/UserInvite";
import { ViewState } from "./UserInviteView";



export class GoogleAccountLinkingError {
    failureReason: string;
    invalidGoogleAccount: string | null;

    constructor(reason: string, account: string | null = null) {
        this.failureReason = reason;
        this.invalidGoogleAccount = account;
    }
}


type GoogleAccountLinkingViewProps = {
    className? : string,
    linkText? : string | null,
    userInvite: UserInvite,
    viewState : ViewState,
    setUserInvite: (invite: UserInvite) => void,
    setLinkingError: (error: GoogleAccountLinkingError | null) => void
};


const GoogleAccountLinkingView = ({className, userInvite, linkText, viewState, setUserInvite, setLinkingError} : GoogleAccountLinkingViewProps) => {

    const [googleOAuthLink, setGoogleOAuthLink] = useState<string | null>(null);
    //const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLinkingIdentity, setIsLinkingIdentity] = useState(false);


    // Based on the current parent view state, we can attempt to link an identity onto the invite.
    // If there is no 'state' or 'code' provided, then we are not attempting to link a Google
    // identity back to the user invitation.

    useEffect(() => {

        if(!viewState.inviteId || !viewState.code || !viewState.state) {
            setIsLinkingIdentity(false);
            return;
        }

        if(userInvite.inviteId !== viewState.inviteId) {
            return;
        }

        setIsLinkingIdentity(true);
        setLinkingError(null);

        const inviteId = viewState.inviteId;
        let newUserInvite : UserInvite | null = null;

        userInvite.applyGoogleAccount(viewState.code, viewState.state).then((userInvite) => {

            newUserInvite = userInvite;
            setLinkingError(null);

        }).catch((e) => {

            let linkingError : GoogleAccountLinkingError | null = null;

            if(e instanceof FailedGoogleAccountLinkingError) {
                linkingError = new GoogleAccountLinkingError(e.reason, e.invalidAccount);
            } else {
                linkingError = new GoogleAccountLinkingError("An issue was encountered while trying to link your Google Account to the Data Platform invitation. Please try again later.");
            }

            setLinkingError(linkingError);

        }).finally(() => {

            window.history.replaceState({}, document.title, window.location.pathname + `?id=${encodeURIComponent(inviteId)}`);
            viewState.state = null;
            viewState.code = null;

            if(newUserInvite) {
                setUserInvite(newUserInvite);
            }
            setIsLinkingIdentity(false);
        });

    }, [userInvite, setUserInvite, setLinkingError, viewState, viewState.inviteId, viewState.code, viewState.state]);


    // When the user invite changes, try and obtain a Google OAuth link.
    // We do not do this however if the view state includes a code/state of linking the Google Account back.

    useEffect(() => {

        if(isLinkingIdentity || (viewState.inviteId && viewState.code && viewState.state)) {
            setIsLoading(false);
            return;
        }

        if(!userInvite.googleAccount) {

            setIsLoading(true);

            userInvite.getGoogleAuthoriseLink().then((link) => {

                setGoogleOAuthLink(link);
                //setLinkingError(null);

            }).catch(() => {

                setLinkingError(new GoogleAccountLinkingError("A server issue was encountered while processing a request. Please try again later."));
                setGoogleOAuthLink(null);

            }).finally(() => {

                setIsLoading(false);
            });

        } else {

            //setLinkingError(null);
            setGoogleOAuthLink(null);
            setIsLoading(false);
        }

    }, [userInvite, setLinkingError, isLinkingIdentity, viewState.inviteId, viewState.code, viewState.state]);



    if(isLoading) {
        return (
            <div className={`${className} google-account-linking-view`}>
                <LoadingIndicator style={LoaderStyle.SimpleCircle}/>
            </div>
        );
    }

    if(isLinkingIdentity) {
        return (
            <div className={`${className} google-account-linking-view linking`}>
                <GoogleAssociateInProgressButton text={"Linking Google Account…"} />
            </div>
        );
    }

    /*if(error) {
        return (
            <div className={`${className} google-account-linking-view error`}>
                <span>{error}</span>
            </div>
        );
    }*/

    if(!googleOAuthLink) {
        return (
            <div className={`${className} google-account-linking-view empty`} />
        );
    }

    return (
        <div className={`${className} google-account-linking-view`}>
            <GoogleAssociateButton buttonText={linkText || "Assign Google Account"} link={googleOAuthLink}/>
        </div>
    );
};

export default GoogleAccountLinkingView;
