import React, {useCallback, useEffect, useState} from 'react';
import './UserInviteView.css';

import Card from "./Card";
import CardHeader from "./CardHeader";
import LoadingIndicator, {LoaderSize, LoaderStyle} from './LoadingIndicator';
import Button from "./Button";
import SectionHeader from "./SectionHeader";

import GoogleAccountLinkingView, { GoogleAccountLinkingError } from "./GoogleAccountLinkingView";
import { GoogleLogo } from './GoogleAssociateButton';

import UserInvite, { ConfirmedAccountDetails } from "../model/UserInvite";
import SiteSettings from "../model/SiteSettings";
import ProductDetailsView from "./ProductDetailsView";
import PinningProjectInfoView from "./PinningProjectInfoView";


export class ViewState {
    inviteId: string | null;
    code: string | null;
    state : string | null;

    constructor(inviteId : (string | null) = null, code: (string | null) = null, state: (string | null) = null) {
        this.inviteId = inviteId;
        this.code = code;
        this.state = state;
    }

    isDifferent(otherState: ViewState) {
        return this.inviteId !== otherState.inviteId || this.code !== otherState.code || this.state !== otherState.state;
    }

    static determineCurrentState() : ViewState {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let inviteId = urlParams.get("id");
        const code = urlParams.get("code");
        const state = urlParams.get("state");

        // On the OAuth redirect back to us the invite ID is stored, base64 encoded as a JSON encoded object
        // within the 'state' parameter.

        if(state) {
            try {
                const decodedState = window.atob(state); // decode the string
                const data = JSON.parse(decodedState);

                if(data && data.s) {
                    inviteId = data.s.toString();
                }
            } catch(e) {
            }
        }

        return new ViewState(
            inviteId && inviteId.length ? inviteId : null,
            code && code.length ? code : null,
            state && state.length ? state : null
        );
    }
}

const ERROR_MESSAGE_LINK_INCORRECT = "The '[product_name]' invitation link is incorrect. Please double check the link you are using and try again.";

const ERROR_MESSAGE_INVITE_NOT_FOUND = "The '[product_name]' invitation provided was not found. Please check to ensure the link you are using is correct and hasn't already been used.";

const ERROR_MESSAGE_SERVER_ISSUE = "An issue was encountered while retrieving details of the '[product_name]' invitation. Please try again later.";

const ERROR_MESSAGE_CONFIRM_ACCOUNT_SERVER_ISSUE = "An issue was encountered while attempting to create your account. Please try again or contact us for further assistance.";

function formatError(error : string, siteSettings : SiteSettings) {
    return error.replaceAll("[product_name]", siteSettings.product_name)
}


const UserInviteHeader = ({siteSettings, skipSectionHeader} : {siteSettings : SiteSettings, skipSectionHeader? : (boolean | undefined)}) => {
    const includeSectionHeader = !(skipSectionHeader || false);

    return (
        <>
            <CardHeader>
                <div className='logo'>
                    <img src={`/ds-logo-white-trimmed.svg`} alt={'Digital Science logo'} />
                </div>
            </CardHeader>
            {includeSectionHeader ? <SectionHeader>Get access to Digital Science datasets on Google BigQuery</SectionHeader> : null}
        </>
    );
};

// <img src={`/${siteSettings.logo}`} alt={siteSettings.display_name} />

const UserInviteFooter = ({siteSettings} : {siteSettings : SiteSettings}) => {
    return (
        <div className='footer'>
            © 2024 Digital Science & Research Solutions Inc. All Rights Reserved
            <br />
            <a href={siteSettings.links.about_us} target="_blank" rel="noopener noreferrer">About us</a>
            &nbsp;·&nbsp;
            <a href={siteSettings.links.privacy} target="_blank" rel="noopener noreferrer">Privacy policy</a>
            &nbsp;·&nbsp;
            <a href={siteSettings.links.legal} target="_blank" rel="noopener noreferrer">Legal terms</a>
        </div>
    )
};


export const UserInviteView = ({siteSettings} : {siteSettings : SiteSettings}) => {

    const [viewState] = useState<ViewState>(ViewState.determineCurrentState());
    const [invite, setInvite] = useState<UserInvite | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [accountLinkingError, setAccountLinkingError] = useState<GoogleAccountLinkingError | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isUnlinking, setIsUnlinking] = useState(false);
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);

    const [confirmedAccount, setConfirmedAccount] = useState<ConfirmedAccountDetails | null>(null);

    const clearStateAndSetError = (error: string) => {
        setError(error);
        setAccountLinkingError(null);
        setInvite(null);
    };

    const refetchUserInvite = useCallback((inviteId : string | null) : Promise<any> => {

        if(!inviteId) {
            setInvite(null);
            setError(formatError(ERROR_MESSAGE_LINK_INCORRECT, siteSettings));
            return Promise.resolve();
        }

        setIsLoading(true);

        return UserInvite.getUserInvite(siteSettings.getPlatformId(), inviteId).then(userInvite => {

            setInvite(userInvite);

            if(!userInvite) {
                clearStateAndSetError(formatError(ERROR_MESSAGE_INVITE_NOT_FOUND, siteSettings));
                return null;
            }

            return userInvite;

        }).finally(() => {

            setIsLoading(false);

        }).catch(() => {

            clearStateAndSetError(formatError(ERROR_MESSAGE_SERVER_ISSUE, siteSettings));
        });

    }, [siteSettings]);

    // Based on the current view state, fetch the user invite.
    useEffect(() => {

        refetchUserInvite(viewState.inviteId);

    }, [viewState, viewState.inviteId, refetchUserInvite]);

    // Unlink a Google Account
    const unlinkGoogleAccount = useCallback(() => {

        if(invite) {
            setIsUnlinking(true);

            invite.unlinkIdentity().then((userInvite) => {
                setInvite(userInvite);
            }).finally(() => {
                setIsUnlinking(false);
            });
        }

    }, [invite, setInvite]);

    const confirmInviteIntoAccount = useCallback(() => {

        if(invite && !isCreatingAccount) {
            setIsCreatingAccount(true);

            invite.confirmAccount().then((accountDetails) => {
                setConfirmedAccount(accountDetails);
            }).catch(() => {
                setError(formatError(ERROR_MESSAGE_CONFIRM_ACCOUNT_SERVER_ISSUE, siteSettings));
            }).finally(() => {
                setIsCreatingAccount(false);
            });
        }

    }, [invite, isCreatingAccount, siteSettings]);


    if(isLoading) {
        return (
            <Card className={'user-invite'}>
                <UserInviteHeader siteSettings={siteSettings} />

                <div className={'loading-holder'}>
                    <LoadingIndicator style={LoaderStyle.BouncingCircle} size={LoaderSize.Normal} />
                </div>

                <UserInviteFooter siteSettings={siteSettings}/>
            </Card>
        );
    }

    if(error || !invite) {
        return (
            <Card className={'user-invite'} showingError={true}>
                <UserInviteHeader siteSettings={siteSettings} skipSectionHeader={true} />

                <p className={'error'}>
                    <b>Error:</b>
                    {error || formatError(ERROR_MESSAGE_INVITE_NOT_FOUND, siteSettings)}
                </p>

                <UserInviteFooter siteSettings={siteSettings} />
            </Card>
        );
    }

    if(confirmedAccount) {

        // If this user is within a managed project, then we provide a link which also sets the billing project at the same time.
        if(confirmedAccount.managedProjectId) {

            const bigQueryManagedProjectLink = `https://console.cloud.google.com/bigquery?authuser=${encodeURIComponent(confirmedAccount.googleAccount)}&project=${encodeURIComponent(confirmedAccount.managedProjectId)}`;

            return (
                <Card className={'user-invite'}>
                    <UserInviteHeader siteSettings={siteSettings} />

                    <p>
                        Your account (<em>{confirmedAccount.googleAccount}</em>) has <b>successfully</b> been granted access!
                    </p>

                    <div>
                        {invite.products.map(function(product, index){
                            return (
                                <React.Fragment key={index}>
                                    <ProductDetailsView
                                        product={product}
                                        siteSettings={siteSettings}
                                        userGoogleAccount={confirmedAccount.googleAccount}
                                        managedProjectId={confirmedAccount.managedProjectId}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>

                    <p className={'extra'}>
                        For your organisation we provide a <em>Google Cloud Project</em> which is configured for Google BigQuery.
                        Your Google Account has also been <b>granted access</b> to use this project.
                        <br />
                        <br />
                        The following link could be helpful to bookmark as it sets, by default, the correct Google Account and GCP project when accessing BigQuery Studio:
                        <br />
                        <a href={bigQueryManagedProjectLink} target="_blank" rel="noopener noreferrer">
                            <LinkIcon className={'link-icon'} />
                            {bigQueryManagedProjectLink}
                        </a>
                    </p>

                    <div>
                        <PinningProjectInfoView />
                    </div>

                    <UserInviteFooter siteSettings={siteSettings} />
                </Card>
            );
        }

        return (
            <Card className={'user-invite'}>
                <UserInviteHeader siteSettings={siteSettings} />

                <p>
                    Your account (<em>{confirmedAccount.googleAccount}</em>) has <b>successfully</b> been granted access!
                </p>

                <div>
                    {invite.products.map(function(product, index){
                        return (
                            <React.Fragment key={index}>
                                <ProductDetailsView
                                    product={product}
                                    siteSettings={siteSettings}
                                    userGoogleAccount={confirmedAccount.googleAccount}
                                    managedProjectId={null}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>

                <div>
                    <PinningProjectInfoView />
                </div>

                <UserInviteFooter siteSettings={siteSettings} />
            </Card>
        );
    }

    if(invite.createdUserId) {
        return (
            <Card className={'user-invite'} showingError={true}>
                <UserInviteHeader siteSettings={siteSettings} skipSectionHeader={true} />

                <p className={'error'}>
                    <b>User Invite already used</b>
                    The user invite you are attempting to use has already been used to create an account for accessing the Digital Science Data Platform.
                    It was associated with the Google Account: {invite.createdUserId}
                </p>

                <UserInviteFooter siteSettings={siteSettings} />
            </Card>
        );
    }

    return (
        <Card className={'user-invite'}>
            <UserInviteHeader siteSettings={siteSettings} />

            <div>
                This user invitation will provide access to the following datasets:
                <ul className={"product-listing"}>
                    {invite.products.map(function(product, index){
                        return (
                            <React.Fragment key={index}>
                                <li>
                                    <img src={`/${product.productIconSource()}`} alt={product.name} /> {product.name}
                                    {product.terms ?
                                        <span className={"terms-of-use"}> – <a href={product.terms} target="_blank" rel="noopener noreferrer">(Usage Guidelines)</a></span>
                                        : null}
                                </li>
                            </React.Fragment>
                        );
                    })}
                </ul>
            </div>

            <p>
                Access to Google BigQuery resources are managed through Google accounts. Please select your organizational Google account by clicking <em>"Assign Google Account"</em>. Once selected,
                use <em>"Create Account"</em> to confirm your access.
            </p>

            <p className={'notice'}>
                If your organization does not use Google Workspace, you can use an alternative Google account or <a href="https://accounts.google.com/SignUp" target="_blank" rel="noopener noreferrer">create a new one</a>.
            </p>

            <div className={`details ${isCreatingAccount ? 'creating-account' : ''}`}>
                <b>Account Details</b>

                <div className={'detail-group'}>
                    <div className={'label'}>Name:</div>
                    <div>{invite.name}</div>
                </div>

                <div className={'detail-group'}>
                    <div className={'label'}>Organisation:</div>
                    <div>{invite.clientName}</div>
                </div>


                <div className={'detail-group'}>
                    <div className={'label'}>Associated Google Account:</div>

                    {invite.googleAccount && !isUnlinking ?
                        <div>
                            <div className={'linked-account'}>
                                <div className={'logo'}><GoogleLogo /></div>
                                <div className={'account'}>{invite.googleAccount}</div>
                                <div className={'unlink'}>
                                    <Button onClick={unlinkGoogleAccount}>Unlink</Button>
                                </div>
                            </div>
                        </div>
                    : null}

                    {invite.googleAccount && isUnlinking ?
                        <div>
                            <div className={'linked-account removing'}>
                                <div className={'logo'}>
                                    <LoadingIndicator size={LoaderSize.Small} style={LoaderStyle.BouncingCircle} />
                                </div>
                                <div className={'account'}>Removing Google Account…</div>
                                <div className={'unlink'}>
                                    <Button disabled={true} onClick={unlinkGoogleAccount}>Unlink</Button>
                                </div>
                            </div>
                        </div>
                    : null}

                    {!invite.googleAccount ?
                        <div>
                            <GoogleAccountLinkingView userInvite={invite} viewState={viewState} setUserInvite={setInvite} setLinkingError={setAccountLinkingError} />
                        </div>
                    : null}

                    {accountLinkingError ?
                        <div>
                            {accountLinkingError.invalidGoogleAccount ?
                                <div className={'linked-account-error invalid'}>
                                    <p>
                                        The Google Account <span>"{accountLinkingError.invalidGoogleAccount}"</span> is not a valid Google Account
                                        for use with the <em>Digital Science Data Platform</em>. Please use your organizational Google Account for assigning
                                        your BigQuery dataset access.
                                    </p>
                                    {/*<p>
                                        Documentation is available which details the steps for creating a Google Account for your existing organizational email address:
                                        <br />
                                        <a href="https://docs.dimensions.ai/bigquery/getting-started.html" target="_blank" rel="noopener noreferrer">https://docs.dimensions.ai/bigquery/getting-started.html</a>
                                    </p>*/}
                                </div>
                            :
                                <div className={'linked-account-error failure'}>{accountLinkingError.failureReason}</div>
                            }
                        </div>
                    : null}
                </div>
            </div>

            {invite.googleAccount && !isUnlinking ? (
                <div className={'confirm-section'}>
                    {!isCreatingAccount ?
                        <div className={'create-account-holder'}>
                            <p className={'notice legal-notice'}>
                                By clicking “Create Account” you agree to comply with the Usage Guidelines
                                <br/>
                                and other terms of your subscription.
                            </p>
                            <Button onClick={confirmInviteIntoAccount} pulse={true}>Create Account</Button>
                        </div>
                    :
                        <span className={'waiting'}>
                            <LoadingIndicator size={LoaderSize.Small} style={LoaderStyle.BouncingCircle} />
                            <span> Creating new account…</span>
                        </span>
                    }

                </div>
            ) : null}

            <UserInviteFooter siteSettings={siteSettings} />

        </Card>
    );
};

export default UserInviteView;



export const LinkIcon = ({className} : {className? : string}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" className={className || ''}>
            <path d="M 3 3 L 3 21 L 21 21 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 3 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
        </svg>
    )
}