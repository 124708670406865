import React, { useEffect, useState } from 'react';
import './App.css';

import Content from './layout/Content';
import Header from './layout/Header';
import Footer from './layout/Footer';

import UserInviteView from "./views/UserInviteView";
import Card from "./views/Card";
import LoadingIndicator, { LoaderStyle, LoaderSize } from "./views/LoadingIndicator";

import SiteSettings from "./model/SiteSettings";


function App() {

    const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        SiteSettings.getCurrentSettings().then((settings : SiteSettings) => {
            setSiteSettings(settings);
            if(settings.page_title) {
                document.title = settings.page_title;
            }
        }).catch((_failure: any) => {
            setError(
                "Fetching the current site settings failed. Please contact our support team if this issue persists."
            );
        });
    }, []);

    if(error) {
        return (
            <div className={'wrapper site-error'}>
                <Card className={'user-invite'} showingError={true}>
                    <p className={'error'}>
                        <b>Error:</b>
                        {error}
                    </p>
                </Card>
            </div>
        );
    }

    if(!siteSettings) {
        return (
            <div className={'wrapper site-settings-load'}>
                <Card className={'user-invite'} showingError={false}>
                    <div className={'loading-holder'}>
                        <LoadingIndicator style={LoaderStyle.BouncingCircle} size={LoaderSize.Normal} />
                    </div>
                </Card>
            </div>
        );
    }

    return (
        <div className={`wrapper ${siteSettings.platform_id}`}>
            <Header/>
            <Content>
                <UserInviteView siteSettings={siteSettings} />
            </Content>
            <Footer/>
        </div>
    );
}

export default App;