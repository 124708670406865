import React from "react";
import './Button.css';

type ButtonProps = {
    className? : string,
    style?: ButtonStyle,
    onClick : any,
    children: React.ReactNode,
    disabled?: boolean,
    pulse?: boolean
};

enum ButtonStyle {
    Primary
}

const Button = ({
    className,
    style= ButtonStyle.Primary,
    disabled= false,
    pulse=false,
    onClick,
    children} : ButtonProps
) => {

    const styleClass = 'primary';

    return (
        <button className={`${className || ''} simple-button ${styleClass || ''} ${pulse ? "pulse" : ""}`} onClick={onClick} disabled={disabled || false}>
            {children}
        </button>
    );
};

export default Button;