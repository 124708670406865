import React from "react";
import './Content.css';

const Content = ({children} : {children: React.ReactNode}) => {
    return (
        <main className={"content"}>
            {children}
        </main>
    );
};

export default Content;