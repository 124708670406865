import { PlatformId } from "./SiteSettings";

const API_ENDPOINT : string = `${process.env.REACT_APP_PLATFORM_API_ENDPOINT}`;

function _getDefaultOAuthRedirectUrl() : string {
    const location = window.location;
    return `${location.protocol}//${location.host}${location.pathname}`;
}


export class UserInvite {

    platformId: string;
    inviteId: string;
    creationDate: string;
    name: string;
    email: string;
    clientName: string;
    googleAccount: (string | null);
    createdUserId: (string | null);
    products: Array<Product>;

    constructor(platformId: PlatformId, data: any) {
        this.platformId = platformId;
        this.inviteId = data.invite_id;
        this.creationDate = data.creation_date;
        this.name = data.name;
        this.email = data.email;
        this.clientName = data.client_name;
        this.googleAccount = data.google_account;
        this.createdUserId = data.created_user_id;

        if(data.products){
            this.products = data.products.map((product : any) => {
                return new Product(product);
            });
        } else {
            this.products = [];
        }
    }

    private apply(data: any) {
        this.inviteId = data.invite_id;
        this.creationDate = data.creation_date;
        this.name = data.name;
        this.email = data.email;
        this.clientName = data.client_name;
        this.googleAccount = data.google_account;
        this.createdUserId = data.created_user_id;
    }

    static getUserInvite(platformId: PlatformId, inviteId : string) : Promise<UserInvite | null> {

        return fetch(`${API_ENDPOINT}/platform/${platformId}/user/invite/${encodeURI(inviteId)}`).then(response => {

            if(response.status === 404) {
                return null;
            }
            return response.json();

        }).then(data => {

            if(!data) {
                return null;
            }
            return new UserInvite(platformId, data);
        });
    }

    getGoogleAuthoriseLink(redirectUrl : string = _getDefaultOAuthRedirectUrl()) : Promise<string | null> {

        const body = {
            redirect_url: redirectUrl
        };

        return fetch(`${API_ENDPOINT}/platform/${this.platformId}/user/invite/${encodeURI(this.inviteId)}/google/generate`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            return data.authorise_link;
        });
    }

    unlinkIdentity() : Promise<UserInvite> {

        const platform = this.platformId;

        return fetch(`${API_ENDPOINT}/platform/${platform}/user/invite/${encodeURI(this.inviteId)}/google/link`, {
            method: 'DELETE'
        })
        .then(response => response.json())
        .then(data => {
            if(!data.successful || !data.user_invite) {
                return this;
            }
            return new UserInvite(platform, data.user_invite);
        });
    }

    applyGoogleAccount(oauthCode: string, oauthState: string, redirectUrl : string = _getDefaultOAuthRedirectUrl()) : Promise<UserInvite> {

        const body = {
            redirect_url: redirectUrl,
            code: oauthCode,
            state: oauthState
        };
        const platform = this.platformId;

        return fetch(`${API_ENDPOINT}/platform/${platform}/user/invite/${encodeURI(this.inviteId)}/google/link`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            if(!data.successful) {
                throw new FailedGoogleAccountLinkingError(data.failure_reason, data.failed_invalid_google_account || null);
            }
            return new UserInvite(platform, data.user_invite);
        });
    }

    confirmAccount() : Promise<ConfirmedAccountDetails> {

        return fetch(`${API_ENDPOINT}/platform/${this.platformId}/user/invite/${encodeURI(this.inviteId)}/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
        .then(response => {
            return response.json().then(data => {
                return {data, response};
            });
        })
        .then(({data, response}) => {
            if(response.status < 200 || response.status > 299) {
                throw new Error(data.error || "Server error");
            }
            return new ConfirmedAccountDetails(data);
        });
    }
}

export class Product {
    name: string;
    location: ProductLocation | null;
    terms: string | null;

    constructor(data: any) {
        this.name = data.name;
        if(data.location) {
            this.location = new ProductLocation(data.location);
        } else {
            this.location = null;
        }
        this.terms = data.terms || null;
    }

    productIconSource() : string {
        return "prod-" + this.name.toLowerCase().replaceAll(" ", "-").replaceAll("&", "") + ".webp"
    }
}

export class ProductLocation {
    project: string;
    dataset: string;

    constructor(data: any) {
        this.project = data.project;
        this.dataset = data.dataset;
    }
}

export class ConfirmedAccountDetails {

    username: string;
    googleAccount: string;
    dataSetId: string;
    dataPlatformProjectId: string;
    managedProjectId: string | null;
    userInvite: UserInvite;

    constructor(data: any) {
        this.username = data.username;
        this.googleAccount = data.google_account;

        this.dataSetId = data.dataset_id;
        this.dataPlatformProjectId = data.data_platform_project_id;
        this.managedProjectId = data.managed_project_id || null;

        this.userInvite = data.user_invite;
    }

}


export class FailedGoogleAccountLinkingError extends Error {

    reason: string;
    invalidAccount: string | null;

    constructor(reason : string, invalidAccount : string | null = null) {
        super(reason);
        this.reason = reason;
        this.invalidAccount = invalidAccount;
    }
}


export default UserInvite;
